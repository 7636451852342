import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import ShippingSummary from './ShippingSummary';
import PrintOrderSummary from '../PrintOrderSummary';

import { fetchPrintOrder } from 'actions/printOrders'
import Breadcrumb from "../../Breadcrumb";

import { STRIPE_PUBLIC_KEY } from '../../../services/settings';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const CheckoutContainer = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		dispatch(fetchPrintOrder()).then((result) => {
			if (result && (!result.firstname || !result.lastname || !result.email || !result.street || !result.street_number || !result.zip_code || !result.city || !result.phone_number)) {
				history.push('/orders/shipping');
			}
		});
	}, [dispatch, history]);

	return (
		<div className="checkout">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<h1 className="title-h1">Commande DUC Online</h1>
					</div>
					<div className="col-12 col-md-9">
						<Breadcrumb step={3} />
					</div>
					<ShippingSummary />
					<div className="col-12">
						<h2 className="title-h2">Documents</h2>
					</div>
					<PrintOrderSummary editable={false} />

					<Elements stripe={stripePromise}>
						<CheckoutForm />
					</Elements>
				</div>
			</div>
		</div>
	);
};

export default CheckoutContainer;
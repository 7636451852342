import React from "react";
import logo from '../assets/img/logo.png';

const Footer = () => {
    return (
        <footer className="footer" id="footer">
            <div className="container">
                <div className="row footer__title-informations">
                    <div className="footer__important-title col-12 col-md-4">
                        <a className="logo__link footer__logo" href="/">
                            <img src={logo} alt="" className="logo__image img" />
                        </a>
                    </div>
                    <div className="col-12 col-md-8">

                    </div>
                    <button className="footer__back-top" type="button">
                        goTop
                    </button>
                </div>
                <div className="footer__privacy-CGU privacy-CGU row">
                    <div className="privacy-CGU__item privacy-CGU__privacy col-12 col-md-6">
                        <span className="privacy-CGU__text-bottom">©</span>
                        <a href="http://www.kern-it.be" className="privacy-CGU__link-bottom">Kern-IT</a>
                        <span className="privacy-CGU__text-bottom">2024 All Rights Reserved</span>
                    </div>
                    <div className="privacy-CGU__item privacy-CGU__align-right col-12 col-md-6">
                        <span className="privacy-CGU__text-bottom">Design and Code By</span>
                        <a href="http://www.kern-it.be" className="privacy-CGU__link-bottom">Kern-IT</a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
import * as services from 'services/api';

export const ADD_PRINT_ORDER = 'ADD_PRINT_ORDER';
export const FETCH_PRINT_ORDER = 'FETCH_PRINT_ORDER';
export const UPDATE_PRINT_ORDER = 'UPDATE_PRINT_ORDER';
export const CONFIRM_PRINT_ORDER = 'CONFIRM_PRINT_ORDER';
export const CANCEL_PRINT_ORDER = 'CANCEL_PRINT_ORDER';
export const ADD_PRINT_ORDER_LINE = 'ADD_PRINT_ORDER_LINE';
export const UPDATE_PRINT_ORDER_LINE = 'UPDATE_PRINT_ORDER_LINE';
export const DELETE_PRINT_ORDER_LINE = 'DELETE_PRINT_ORDER_LINE';


export const addPrintOrder = (order) => async dispatch => {
	const result = await services.addPrintOrder(order);
	if(result.success) {
		localStorage.setItem('token', result.token);
	}

	dispatch(addPrintOrderAsync(result));
};

const addPrintOrderAsync = (order) => ({
	type: ADD_PRINT_ORDER,
	payload: order
});

export const fetchPrintOrder = () => async dispatch => {
	const result = await services.fetchPrintOrder();
	if(result) {
		dispatch(fetchPrintOrderAsync(result));
	}
	return result;
};

const fetchPrintOrderAsync = (order) => ({
	type: FETCH_PRINT_ORDER,
	payload: order
});

export const updatePrintOrder = (id, order) => async dispatch => {
	const result = await services.updatePrintOrder(id, order);
	dispatch(updatePrintOrderAsync(result));
};

export const updatePrintOrderAsync = (order) => ({
	type: UPDATE_PRINT_ORDER,
	payload: order
});

export const confirmPrintOrder = (id, order) => async dispatch => {
	const result = await services.confirmPrintOrder(id, order);
	dispatch(confirmPrintOrderAsync(result));
};

export const confirmPrintOrderAsync = (order) => ({
	type: CONFIRM_PRINT_ORDER,
	payload: order
});

export const cancelPrintOrder = () => async dispatch => {
	// TODO Uncomment when the cancel endpoint is done
	// await services.cancelPrintOrder();
	localStorage.removeItem('token');
	dispatch(cancelPrintOrderAsync());
};

export const cancelPrintOrderAsync = () => ({
	type: CANCEL_PRINT_ORDER
});

export const addLine = ({ data, tmp_id, options }) => async dispatch => {
	const result = await services.addLine({ data, options });
	dispatch(addLineAsync({ ...result, tmp_id }));
};

export const addLineAsync = (line) => ({
	type: ADD_PRINT_ORDER_LINE,
	payload: line
});

export const updateLine = (id, line) => async dispatch => {
	const result = await services.updateLine(id, line);
	dispatch(updateLineAsync(result));
};

export const updateLineAsync = (line) => ({
	type: UPDATE_PRINT_ORDER_LINE,
	payload: line
});

export const deleteLine = (id) => async dispatch => {
	const result = await services.deleteLine(id);
	if(result.success) {
		dispatch(deleteLineAsync(id));
	}
};

export const deleteLineAsync = (id) => ({
	type: DELETE_PRINT_ORDER_LINE,
	payload: id
});
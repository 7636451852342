import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import CheckoutFailed from './CheckoutFailed';
import CheckoutSuccess from './CheckoutSuccess';

import * as services from 'services/api';


const CheckoutForm = () => {
	const currentPrintOrder = useSelector(state => state.currentPrintOrder);
	const [paymentMethod, setPaymentMethod] = useState('bancontact');
	const [ownerName, setOwnerName] = useState();
	const [paymentIntent, setPaymentIntent] = useState();
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const stripe = useStripe();
	const elements = useElements();

	useEffect(() => {
		let pollCount = 0;
		const pollPaymentIntentStatus = async () => {
			const { payment_intent } = await services.getPaymentIntentStatus(currentPrintOrder.payment_id);
			if(payment_intent) {
				setPaymentIntent(prevPaymentIntent => ({ ...prevPaymentIntent, ...payment_intent }));
			}

			if(payment_intent?.status === 'succeeded') {
				setSuccess(true);
				setLoading(false);
			}
			else if(payment_intent?.status === 'canceled') {
				setError(true);
				setLoading(false);
			}
			else if(pollCount < 5) {
				pollCount++;
				setLoading(true);
				setTimeout(pollPaymentIntentStatus, 3000);
			}
		};

		const getIntentStatus = async () => {
			const url = new URL(window.location.href);
			const source_id = url.searchParams.get('source');
			const client_secret = url.searchParams.get('client_secret');
			if(source_id && client_secret) {
				pollPaymentIntentStatus();
			}
		};

		if(stripe && currentPrintOrder.payment_id) {
			getIntentStatus();
		}
	}, [stripe, currentPrintOrder.payment_id]);

	useEffect(() => {
		const url = new URL(window.location.href);
		if (!url.searchParams.get('source') && !url.searchParams.get('client_secret')) {
			/*const confirmPrintOrder = async () => {
				const { payment_intent } = await services.confirmPrintOrder();
				setPaymentIntent(payment_intent);
			};
			confirmPrintOrder();*/
		}
	}, []);

	useEffect(() => {
		const confirmPrintOrder = async () => {
			const result = await services.confirmPrintOrder();
			setPaymentIntent(result.payment_intent);
		};
		if (!paymentIntent) {
			confirmPrintOrder()
		}
	}, [paymentIntent]);

	const handleChangePaymentMethod = (e) => {
		console.debug("Change Method");
		setPaymentMethod(e.target.value);
	};

	const handleChangeOwnerName = (e) => {
		setOwnerName(e.target.value);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!stripe || !elements || !paymentIntent) {
			return;
		}

		if(paymentMethod === 'card') {
			const cardElement = elements.getElement(CardElement);
			const { error } =
			await stripe.confirmCardPayment(
				paymentIntent.client_secret,
				{
					payment_method: { card: cardElement }
				}
			);
			if (error) {
				console.debug("Payment error");
				console.debug(error);
				setError(true);
			} else {
				console.debug("Payment succeed");
				setSuccess(true);
			}
		}
		else if(paymentMethod === 'bancontact') {
			stripe.createSource({
				type: 'bancontact',
				amount: paymentIntent.amount,
				currency: 'eur',
				owner: {
					name: ownerName
				},
				redirect: {
					return_url: window.location.href,
				},
				metadata: {
					paymentIntent: paymentIntent.id
				}
			}).then(({ source, error }) => {
				if(error) {
					console.debug("Payment error", error);
					setError(true);
				}
				else {
					window.location.replace(source.redirect.url);
				}
			});
		}
	};

	if (error) {
		return <CheckoutFailed />;
	}

	if(success) {
		return <CheckoutSuccess />;
	}

	if (loading) {
		return <p>Loading...</p>
	}

	switch(currentPrintOrder?.status) {
		case 'new': {
			return (
				<form onSubmit={handleSubmit} className="col-12 col-md-8">
					<h2 className="title-h2">Paiement</h2>
					<p>
						En cliquant sur le bouton « payer », vos données sont redirigées vers la plateforme Stripe. Ce service en ligne vous permet d’effectuer votre paiement en toute sécurité et rapidement. Aucune donnée de paiement ne transite par nos serveurs. Après confirmation de votre paiement, vous recevez une confirmation de paiement par email (n’oubliez pas de vérifier vos spams).
					</p>
					<div className="checkout__payment">
						<div className="checkout__choice-payment row">
							<div className="col-6 checkout__container-choice-payment checkout__container-choice-payment--bancontact">
								<input type="radio" name="payment_method" id="payment-bancontact" className="checkout__radio" value="bancontact" defaultChecked onChange={handleChangePaymentMethod} />
								<label htmlFor="payment-bancontact" className="checkout__label checkout__label--bancontact">Bancontact</label>
							</div>
							<div className="col-6 checkout__container-choice-payment checkout__container-choice-payment--card">
								<input type="radio" name="payment_method" id="payment-card" value="card" className="checkout__radio" onChange={handleChangePaymentMethod} />
								<label htmlFor="payment-card" className="checkout__label checkout__label--card">Carte de crédit</label>
							</div>
						</div>
						<div className="checkout__container-card-element">
							{paymentMethod === 'bancontact' ?
								<input type="text" name="name" className="checkout__bancontact-input" onChange={handleChangeOwnerName} placeholder="Nom du titulaire du compte Bancontact" />
								:
								<CardElement options={{ hidePostalCode: true }} />
							}
						</div>
						<div className="checkout__container-checkboxes">
							<div className="form__fieldset--row">
								<label htmlFor="first" className="form__label"><a
									href="https://www.ciaco.coop/papeterie/conditions-generales-de-vente" target="_blank" rel="noopener noreferrer" >J’accepte les conditions générales de vente</a></label>
								<input type="checkbox" id="first" className="input checkbox" required={true} />
							</div>
							<div className="form__fieldset--row">
								<label htmlFor="sec" className="form__label"><a
									href="https://www.ciaco.coop/coop/mentions-legales" target="_blank" rel="noopener noreferrer" >J’accepte la politique de confidentialité</a></label>
								<input type="checkbox" id="sec" className="input checkbox" required={true} />
							</div>
						</div>
						<button type="submit" className="button button--pay" disabled={!stripe}>
							Payer
						</button>
					</div>
				</form>
			);
		}
		case 'paid':
		case 'printed':
		case 'printing':
			return (
				<p>Votre commande est en cours de traitement</p>
			);
		case 'shipped':
			return (
				<p>Votre commande a été expédiée</p>
			);
		default:
			return null;
	}
};

export default CheckoutForm;
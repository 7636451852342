import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { addPrintOrder, fetchPrintOrder } from 'actions/printOrders';

const Overview = () => {
    const currentPrintOrder = useSelector(state => state.currentPrintOrder);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(fetchPrintOrder());
    }, [dispatch]);

    const handleClick = async () => {
        if(!currentPrintOrder.id || currentPrintOrder.status !== 'new') {
            await dispatch(addPrintOrder());
        }
        await history.push('/orders/new');
    };

    return (
        <div className="overview">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1 className="title-h1">Ciaco Print Online</h1>
                    </div>
                    <div className={"col-12 col-md-11 col-lg-9"}>
                        <div className="overview__container-button">
                            <button type="button" className="overview__button button" onClick={handleClick}>Je suis thésard</button>
                            <button type="button" className="overview__button button" onClick={handleClick}>Je suis éditeur</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Overview;

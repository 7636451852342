import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import 'assets/scss/main.scss';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Overview from 'components/printOrders/Overview';
import UploadForm from 'components/printOrders/UploadForm';
import PrintOrderForm from 'components/printOrders/PrintOrderForm';
import CheckoutContainer from 'components/printOrders/checkout/CheckoutContainer';
import ScrollToTop from 'components/common/ScrollToTop';


const App = () => (
    <Router>
        <ScrollToTop />
        <div className="App">
            <Header/>
            <Switch>
                <Route exact path="/">
                    <Overview />
                </Route>
                <Route exact path="/orders/new">
                    <UploadForm />
                </Route>
                <Route exact path="/orders/shipping">
                    <PrintOrderForm />
                </Route>
                <Route exact path="/orders/checkout">
                    <CheckoutContainer />
                </Route>
            </Switch>
            <Footer/>
        </div>
    </Router>
);

export default App;